import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerPage from "../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../components/section/TextImage/textImage"
import SectionMarketingServices from "../components/section/MarketingServices/marketingServices"
import CallToAction from "../components/section/CallToAction/callToAction"

const MarketingDigitalPage = () => {
    const data = useStaticQuery(graphql`
    query {
      marketingDigitalImage1: file(relativePath: { eq: "images/sections/marketing-digital/tienes-plan-marketing-digital-ibxagency.png" }) {
        childImageSharp {
            fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
            }
        }
      },
      marketingDigitalImage2: file(relativePath: { eq: "images/sections/marketing-digital/por-que-una-estrategia-marketing-digital-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      marketingDigitalImage3: file(relativePath: { eq: "images/sections/marketing-digital/planificar-ejecutar-medir-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Expertos Marketing Online"
                description="Agencia de Marketing Digital en Barcelona experta en páginas web, tiendas online, blogs, posicionamiento SEO, SEM, redes sociales y content marketing."
            />

            <BannerPage
                title="Expertos en Marketing Digital en Barcelona"
                text="Planes de Marketing Digital integrales enfocados al ROI de tu empresa. ¡Buscamos resultados!"
                color="green"
            />

            <SectionTextImage
                title1="¿Tienes un plan"
                title2="de Marketing Digital?"
                text={<Fragment>¿Promocionas tu empresa en los motores de búsqueda dejando al azar y a la suerte como dueños del resultado? O por lo contrario… ¿Dispones de un <strong>Plan de Marketing Digital</strong>? ¡Solo con una <strong>estrategia personalizada</strong> es posible llegar a la cúspide! No dejes nada en el aire y mide todo aquello que reporta resultados a tu negocio.</Fragment>}
                image={data.marketingDigitalImage1.childImageSharp.fluid}
                imageAlt="Tienes un plan de marketing digital - Agencia Marketing Digital"
                color="green"
                containerTextRight={true}
            />
            <SectionTextImage
                title1="¿Por qué"
                title2="una estrategia?"
                text={<Fragment>Para que tu empresa tenga notoriedad, visibilidad y reputación online en los motores de búsqueda, es imprescindible implementar un <strong>Plan de Marketing Digital integral</strong>, es decir, una estrategia hecha a medida para poder conseguir los objetivos planteados.</Fragment>}
                image={data.marketingDigitalImage2.childImageSharp.fluid}
                imageAlt="Marketing Digital - Agencia Marketing Digital"
                color="green"
                containerTextRight={false}
            />
            <SectionTextImage
                title1="¡Planifica, ejecuta"
                title2="y mide"
                text={<Fragment>La clave del éxito está en definir qué información publicar, en qué productos y servicios enfocar la difusión, en qué portales, buscadores o redes sociales mostrarlo, qué contenido promocionar, cuándo publicar… ¡Solo así, se podrá medir qué acciones reportan un ROI a la empresa.</Fragment>}
                image={data.marketingDigitalImage3.childImageSharp.fluid}
                imageAlt="Marketing Digital - Agencia Marketing Digital"
                color="green"
                containerTextRight={true}
            />
            <hr className="space-2-bottom space-3-bottom--lg" />
            <SectionMarketingServices />
            <CallToAction
                color="green"
                title="¿Necesitas un plan de marketing digital para tu empresa?"
                text={<Fragment>Somos tu <strong>Agencia de Marketing Digital en Barcelona</strong></Fragment>}
                button="Pide presupuesto"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default MarketingDigitalPage
